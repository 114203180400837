body{
    background-color: white;
    margin:0;


}
a, p, span{
    font-family: var(--font-family);
    font-weight:500;
    font-size: 16px;
}
@media (max-width: 767px) {
.reveal-motion-span{
    transform: translateY(0%) translateZ(0px) !important;
    opacity: 1 !important;
}
}