.parallax-image-container {
  position: relative;
  overflow: hidden;
  max-height: fit-content;
}

.image-container{
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  padding: 0;
  scale: 1.15;
}
.parallax-wrap{
  position: relative;
  filter: grayscale(1);
}